import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p><inlineCode parentName="p">{`Progress Indicator`}</inlineCode>{` merupakan unsur UI yag digunakan untuk menginformasi user tentang status dari proses yang sedang berjalan, memberikan jaminan bahwa sistem bekerja dengan seharusnya dan tidak menunggu interaksi dari user.`}</p>
    </PageDescription>
    <h2>{`Variant`}</h2>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="Bar" titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "640px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAAA0ElEQVQ4y+2TTw+CIADF+/4fqda9Wh3a6lJrax5aNUsBAQXqpfgns7C1dal5+O0hwvM9GT0uFb5JrzP8IcNIJJXWqb/7OGG5UcT6aa7VsP51lmqJfeYxAhKBMNFIrSq4TAptJMwWaqVxMRpGa8TK4Oif0R8MMZpMsT/4di43vlOavky43kWYbykWHkXAsqQqVYmQxSBRAspVBXMlLA1FOl56DONViNmG4ERzQyp0Ts2MFPq+clr1aoytLaR6qOaitbI9EJ7T/PkunAm7u/ynhjcbBhJP9g/YagAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/2da7221f69ba0cfc785dd66dc611554c/d67c7/bar.png",
              "srcSet": ["/static/2da7221f69ba0cfc785dd66dc611554c/7fc1e/bar.png 288w", "/static/2da7221f69ba0cfc785dd66dc611554c/a5df1/bar.png 576w", "/static/2da7221f69ba0cfc785dd66dc611554c/d67c7/bar.png 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="Circle" titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "640px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAAAwElEQVQ4y+2UywrCMBBF+/8/5Dfoxq2oS1HwmTbPSYvXjCW1WaikKrjo4jKEMIc7j6TQ1uObKkbgb4CKZVjUnkOUQf1zHtBwfCSbEJu6hnH+LfRJydQ5ZNheOEyXAqfKwdEHDjkK5VFpj/VOYTI/4lBaWJfpMALLAJK6Tca1wWIjMVsJ1KF8ORjY9ZNwkYTt2d7bMMhhLJmhrFK3g9E88bw9pATKTqNbZShZqWyHsdyo9C7jpfQT+3oFGz+HPwXeABR2E9Q8CqYGAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/3db34990d1b407c8509f21e116b23a4c/d67c7/circle.png",
              "srcSet": ["/static/3db34990d1b407c8509f21e116b23a4c/7fc1e/circle.png 288w", "/static/3db34990d1b407c8509f21e116b23a4c/a5df1/circle.png 576w", "/static/3db34990d1b407c8509f21e116b23a4c/d67c7/circle.png 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>
    <h2>{`Size`}</h2>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="Small" titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "640px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAAAwElEQVQ4y+2UywrCMBBF+/8/5Dfoxq2oS1HwmTbPSYvXjCW1WaikKrjo4jKEMIc7j6TQ1uObKkbgb4CKZVjUnkOUQf1zHtBwfCSbEJu6hnH+LfRJydQ5ZNheOEyXAqfKwdEHDjkK5VFpj/VOYTI/4lBaWJfpMALLAJK6Tca1wWIjMVsJ1KF8ORjY9ZNwkYTt2d7bMMhhLJmhrFK3g9E88bw9pATKTqNbZShZqWyHsdyo9C7jpfQT+3oFGz+HPwXeABR2E9Q8CqYGAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/3db34990d1b407c8509f21e116b23a4c/d67c7/circle.png",
              "srcSet": ["/static/3db34990d1b407c8509f21e116b23a4c/7fc1e/circle.png 288w", "/static/3db34990d1b407c8509f21e116b23a4c/a5df1/circle.png 576w", "/static/3db34990d1b407c8509f21e116b23a4c/d67c7/circle.png 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="Medium" titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "640px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAABSklEQVQ4y82UwVLCMBRF+W5/QRfu9QPc67DDpT+gG1cwhGHGKSO0kMJri9fctMEQWmg7juMik9emObn3vdcM9DbDb47BnwE3KYdUsRyGe24NLEGM5RA7eKLl5JCzwBDGOd2KnXeSASggWW7BTdAToA9LdIaVzqEWgtvhDHejOSYfGl/74ghaCwxhsYFFSW7neCNQUYrR6yeuHsZ4eV82Qj3gsTLC3DPXS8t7LOKdhY6NUtpvZXmdFkZZ7hWmzNXKKCWUSu+f5zanfqEaFb4pjeWaBfl576BilE6N/ZsnZVWHhQmA5YbrR2U2aRv7PdgZSPm0QTu0RXu02cuygzLRbA0mPjIFIICqpCpKVBVlcqkoboEnsiXYGtxINbQ47do2dVCqoDXmi4Nx68YOfyVuoCXmicnv9euFH/kXQq/Loc5+2Dadr69/d2N/A3CzArTMpiHyAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/2d490513f31e986fec17486321ef51aa/d67c7/circle-md.png",
              "srcSet": ["/static/2d490513f31e986fec17486321ef51aa/7fc1e/circle-md.png 288w", "/static/2d490513f31e986fec17486321ef51aa/a5df1/circle-md.png 576w", "/static/2d490513f31e986fec17486321ef51aa/d67c7/circle-md.png 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="Large" titleColor="dark" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "640px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAAB8ElEQVQ4y42VTU7DMBCFc0FWdFWQuAg3YAESIFQWsOYCrJDgBkhUVamEhAqUQv+b39pOO/g5ceIat81iVCexP795M3a9KOFkRxjjl6nfLNhaZHPKsRmeDXLBYvmNMU7xgpMfMQqizVBvkyqMs+A0Cxl9DmOaBAsiSilNhQKbateALpgfcRr6gsbhklpfCR1ddah+/krHd+/00vUVOHBAPTNVDZsEgn5mKY0DocB4P5Xq3vohXT98095Ji24e+0SrEqrDM9Vp2O9cTpTPcZE2U/4xLqSyJfXGMdXO2hlUKvUNTz0TBjVQFmypMvxcLVMFhdKmTD8VoiiUl03MAIO5UGnGxiY2VBcJShsyfXiqVeYKy9bwk5VDndlWpVIu04enBxevyl9YknvIKJEPw/mC7p+nVo+5mj4DAjDxF1SXwI9BpPwtgHjoype107bqNb2b6yRUAuIjZB9edlQaPP9oQyunDENhLAyG0TAcxtuQykWBbyg9WgCt0BvFqjWw0AYWbTPa0jYYaJW3T33al15iAVQgNaSD4Lqx5TfMwdx/jW3ujl1wnDARuyMl+ATzERg38qOnYK6jZx7sAip3RSrwB6bX88AY75q7Lgcb6ueeYhEqiLZATPX1JXZcX3Zb6EJhkb4UWO5l5QvWBbUrXOUv4A//DOsbwPNUjgAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/25ebd2c142604470bdaf49d37642f623/d67c7/circle-lg.png",
              "srcSet": ["/static/25ebd2c142604470bdaf49d37642f623/7fc1e/circle-lg.png 288w", "/static/25ebd2c142604470bdaf49d37642f623/a5df1/circle-lg.png 576w", "/static/25ebd2c142604470bdaf49d37642f623/d67c7/circle-lg.png 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      